import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Channel, Topic } from "../constants";
import { NativeActionCreators } from "../redux/action-creators";
import { MessageBrokerUtils, StoreUtils, TypeUtils } from "../utils";

export const useNative = () => {
  const dispatch = useDispatch();

  const initNative = useCallback(
    data => dispatch(NativeActionCreators.initState(data)),
    [dispatch]
  );

  //ensure we have store data because on refresh we don't get again nativeInit data on topic
  useEffect(() => {
    const storeBiometricsFlowEnabled = StoreUtils.getNativeDataStoreItem(
      "BiometricsFlowEnabled"
    );
    const isBiometricsFlowEnabled = TypeUtils.toBoolean(
      storeBiometricsFlowEnabled
    );

    const storeCasinoInSportEnabled = StoreUtils.getNativeDataStoreItem(
      "CasinoInSportEnabled"
    );
    const isCasinoInSportEnabled = TypeUtils.toBoolean(
      storeCasinoInSportEnabled
    );

    const storedTouchIdCanBeUsed =
      StoreUtils.getNativeDataStoreItem("IsTouchIdCanBeUsed");
    const isTouchIdCanBeUsed = TypeUtils.toBoolean(storedTouchIdCanBeUsed);

    dispatch(
      NativeActionCreators.initState({
        isTouchIdCanBeUsed: isTouchIdCanBeUsed,
        isBiometricsFlowEnabled: isBiometricsFlowEnabled,
        isCasinoInSportEnabled: isCasinoInSportEnabled
      })
    );
  }, [dispatch]);

  //ensure we have store data because native data store sometimes doesn't have values when app was rendered
  useEffect(() => {
    const initNativeSubscription = MessageBrokerUtils.subscribe({
      channel: Channel.NATIVE_CHANNEL,
      topic: Topic.nativeInit,
      callback: data => {
        initNative(data);
      }
    });

    return () => {
      initNativeSubscription?.unsubscribe();
    };
  }, [initNative]);

  const setBiometricsFlowEnabled = useCallback(
    isEnabled =>
      dispatch(NativeActionCreators.setBiometricsFlowEnabled(isEnabled)),
    [dispatch]
  );

  useEffect(() => {
    const biometricsStatusSubscription = MessageBrokerUtils.subscribe({
      channel: Channel.BIOMETRICS,
      topic: Topic.biometricsStatus,
      callback: data => {
        const isEnabled = TypeUtils.toBoolean(data?.isEnabled);

        setBiometricsFlowEnabled(isEnabled);
      }
    });

    return () => {
      biometricsStatusSubscription?.unsubscribe();
    };
  }, [setBiometricsFlowEnabled]);
};
