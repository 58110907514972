import { useEffect } from "react";

/**
 * deprecated, Dont use its not imported at the moment
 * @param hide
 */
export const useAppContentHider = hide => {
  useEffect(() => {
    const ucContainerDiv = document.getElementById("uc-container");
    hide
      ? ucContainerDiv.classList.add("hideMainContent")
      : ucContainerDiv.classList.remove("hideMainContent");
  }, [hide]);
};
