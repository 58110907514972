import Loadable from "react-loadable";

import { EmptyComponent } from "../common/EmptyComponent";

export const ThemeModeMenu = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "menu/themeMode" */

      "./ThemeModeMenu"
    ),
  loading: EmptyComponent
});
