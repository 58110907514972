export const getSVGImagePath = function (iconName) {
  const defaultPath = require(`../../../styles/svgs/default-icon.svg`);
  if (!iconName) {
    return defaultPath;
  }

  try {
    return require(`../../../styles/svgs/${iconName}.svg`);
  } catch (e) {
    console.error(
      `SVGIconV2: getSVGImage: failed to load icon with the following name: ${iconName}`
    );
    return defaultPath;
  }
};
