import { Item } from "../components/Menu";
import { UrlUtils } from "./urlUtils";

export class GeneralUtils {
  /**
   * Receives a number and prepends a 0 to it if it's less than 10.
   * @param value - Number to format.
   * @returns {string} - Formatted string.
   */
  static addZero(value) {
    return `0${value}`.slice(-2);
  }

  /**
   * Format seconds to HH:mm
   * @param {number} timeInSeconds
   * @returns {string}
   */
  static formatHour(timeInSeconds) {
    const totalHours = timeInSeconds / 3600;
    const totalMinutes = (timeInSeconds - totalHours * 3600) / 60;
    return `${GeneralUtils.addZero(totalHours)}:${GeneralUtils.addZero(
      totalMinutes
    )}`;
  }

  /**
   * Format amounts based on locale and currency
   * @param amount number to be formatted
   * @param culture locale
   * @param currency
   * @returns {string}
   */
  static formatAmount = (amount, culture, currency) => {
    try {
      return new Intl.NumberFormat(culture, {
        style: "currency",
        currency: currency
      })
        .format(amount)
        .trim();
    } catch (e) {
      console.error(e);
    }
  };

  static revertCurrencyToNumber = formattedAmount => {
    try {
      // Remove currency symbol
      const amountWithoutSymbol = formattedAmount.replace(/[^\d.,]/g, "");

      // Replace comma or dot with decimal point (.)
      const amountWithDecimal = amountWithoutSymbol
        .replace(/[,.](?=.*[,.])/g, "")
        .replace(",", ".");

      // Parse the string to a float
      return parseFloat(amountWithDecimal);
    } catch (e) {
      console.error(e);
    }
  };

  /**
   *
   * @returns
   * @param input
   */
  static capitalizeFirstLetter(input) {
    if (!!input) return input.charAt(0).toUpperCase() + input.slice(1);

    return input;
  }

  static promiseTimeout = (ms, promise) => {
    return new Promise((resolve, reject) => {
      let timeout = new Promise(resolve => {
        let id = setTimeout(() => {
          clearTimeout(id);
          resolve();
        }, ms);
      });

      return Promise.all([timeout, promise])
        .then(() => resolve())
        .catch(() => reject());
    });
  };

  static getTimezoneOffset() {
    return (new Date().getTimezoneOffset() / 60) * -1;
  }

  static mapMenuItems = items => {
    if (!items) return null;
    items = items.map(
      item =>
        new Item({
          ...item,
          iconKey:
            (item.iconKey &&
              !item.iconKey.startsWith("#") &&
              `#${item.iconKey}`) ||
            undefined,
          URL: UrlUtils.getURLWithLang(item.URL),
          items: GeneralUtils.mapMenuItems(item.items)
        })
    );

    return items;
  };

  static isPrerenderRequest = () => {
    return navigator.userAgent.toLowerCase().indexOf("prerender") !== -1;
  };

  static getFormattedSessionTime = sessionTimeInSeconds => {
    const radix = 10;
    const totalHours = parseInt(
      (sessionTimeInSeconds / 3600).toString(),
      radix
    );
    const totalMinutes = parseInt(
      ((sessionTimeInSeconds - totalHours * 3600) / 60).toString(),
      radix
    );
    return `${totalHours < 10 ? `0${totalHours}` : totalHours}:${
      totalMinutes < 10 ? `0${totalMinutes}` : totalMinutes
    }`;
  };
}
