import { useCallback } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { IgnoreClickOutsideAttribute } from "../constants";
import { ExternalUtils } from "../utils";

const findIfIgnoredByRules = rules => async event => {
  if (!rules) return false;

  let ignored = false,
    index = 0;

  while (!ignored && index < rules.length) {
    ignored = await rules[index](event);
    index++;
  }

  return ignored;
};

export const useClickOutside = (callback, options) => {
  const wrappedCallback = useCallback(
    async event => {
      const isIgnored = await findIfIgnoredByRules(ignoreRulesList)(event);
      if (isIgnored) return;

      const isValidCallbackFn = typeof callback === "function";
      if (isValidCallbackFn) callback(event);
    },
    [callback]
  );

  return useOnclickOutside(wrappedCallback, {
    detectIFrame: false,
    ...options
  });
};

const defaultIgnoreRule = event => {
  const containers = document.querySelectorAll(
    `[${IgnoreClickOutsideAttribute}]`
  );

  if (!containers || containers.length === 0) return false;

  return Array.from(containers).some(container => {
    return container.contains(event?.target);
  });
};

const cookieMessageIgnoreRule = async event => {
  const cookieBannerContainerId =
    await ExternalUtils.UCSDKService.getB2CCookieContainerIdAsync();
  if (!cookieBannerContainerId) return false;

  const cookieBannerContainer = document.getElementById(
    cookieBannerContainerId
  );
  if (!cookieBannerContainer) return false;

  return cookieBannerContainer.contains(event?.target);
};

const ignoreRulesList = [defaultIgnoreRule, cookieMessageIgnoreRule];
