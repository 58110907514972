import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAsyncEffect } from "../components/common/hooks";
import { FeatureActionCreators } from "../redux/action-creators";
import { UserContextSelectors } from "../redux/selectors";
import { PageContextUtils, ExternalUtils } from "../utils";

export const useFeatureStore = () => {
  const isAuthenticated = useSelector(UserContextSelectors.isAuthenticated);

  const dispatch = useDispatch();

  useAsyncEffect(
    async () => {
      const abTestActiveFeatures =
        await ExternalUtils.UCSDKService.getActiveAbTestFeaturesAsync();

      dispatch(
        FeatureActionCreators.setABTestActiveFeatures(abTestActiveFeatures)
      );
    },
    async () => Promise.resolve(),
    [isAuthenticated]
  );

  useEffect(() => {
    const { features } = PageContextUtils.getFeaturesData();

    dispatch(
      FeatureActionCreators.setActiveFeatures(
        // this list contains features that are either active or should be AB tested
        features.filter(
          feature =>
            feature.value.toLowerCase() === "on" ||
            feature.value.toLowerCase() === "ab"
        )
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
