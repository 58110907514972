import { useResizeDetector as useResizeDetectorInner } from "react-resize-detector";

export const useResizeDetector = ({
  handleHeight,
  handleWidth,
  onResize,
  refreshMode,
  refreshOptions,
  refreshRate,
  skipOnMount,
  targetRef
}) => {
  return useResizeDetectorInner({
    handleHeight,
    handleWidth,
    onResize,
    refreshMode,
    refreshOptions,
    refreshRate,
    skipOnMount,
    targetRef
  });
};
